import React, { useState, useEffect } from "react";
import { Menu, MenuItem, Select, SelectItem } from "../../widgets";
import {
  AllViewsIcon,
  FieldWeightIcon,
  InstallIcon,
  MappingIcon,
  RerankResultsIcon,
  SearchPreviewIcon,
  SynonymsIcon,
  Icon_Size,
  DropdownIcon,
  Icon_Color,
  Icon,
} from "../../widgets";

import { useHistory, useParams } from "react-router-dom";
import { useEngineStore, useViewStore, viewStoreApi } from "../../store";
import { ViewService } from "../../service";
import { PARAM, getParamFromURL } from "../../utils";
import styles from "./styles.module.css";

const colorConfig = {
  default: Icon_Color.Dark,
  selected: Icon_Color.Blue500,
};

export function Views() {
  const history = useHistory();
  const params = useParams();
  const [menu, setMenu] = useState(["1"]);

  let { views, currentViewId } = useViewStore((state) => ({
    views: state.views,
    currentViewId: state.currentViewId,
  }));

  let { currentEngineId } = useEngineStore((state) => ({
    currentEngineId: state.currentEngineId,
  }));

  useEffect(() => {
    checkPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTimeout(() => {
      checkPage();
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEngineId]);

  const checkPage = () => {
    let selectedKey = getParamFromURL(PARAM.viewPage);
    let viewId = getParamFromURL(PARAM.viewId);
    if (viewId === "allviews") {
      updateMenu(viewId);
      // setCurrentPage(collectionId);
    } else {
      updateMenu(selectedKey);
      // setCurrentPage(selectedKey);
    }
  };

  const onCollectionChange = (value) => {
    if (value) {
      ViewService.getView(currentEngineId, value).then((response) => {
        viewStoreApi.getState().setCurrentView(response.view_config, value);
        let route = getParamFromURL(PARAM.viewPage);
        route = !!route ? route : getParamFromURL(PARAM.viewId);
        onClickMenuItem(route, value);
      });
    }
  };

  const onClickMenuItem = (route, value) => {
    updateMenu(route);
    if (route !== "allviews") {
      history.push(
        `/engine/${currentEngineId}/view/${value || currentViewId}/${route}`
      );
    } else {
      history.push(`/engine/${currentEngineId}/view/${route}`);
    }
  };

  const updateMenu = (selectedKey) => {
    if (selectedKey === "mapping") {
      setMenu(["1"]);
    } else if (selectedKey === "rerank") {
      setMenu(["2"]);
    } else if (selectedKey === "synonyms") {
      setMenu(["3"]);
    } else if (selectedKey === "fieldweight") {
      setMenu(["4"]);
    } else if (selectedKey === "searchpreview") {
      setMenu(["7"]);
    } else if (selectedKey === "install") {
      setMenu(["8"]);
    }
    if (selectedKey === "allviews") {
      setMenu(["6"]);
    }
  };

  return (
    <div className="sideMenu">
      <Select
        className={`${styles.select} paragraph300`}
        value={currentViewId}
        bordered={false}
        // style={{ width: "100%" }}
        onChange={onCollectionChange}
        suffixIcon={<Icon src={DropdownIcon} size={Icon_Size.Icon12} />}
      >
        {views &&
          views.map((item) => {
            return (
              <SelectItem value={item.view} key={item.view}>
                {/* <Icon src={BookmarkIcon} className={styles.collectionIcon} /> */}
                {item.view}
              </SelectItem>
            );
          })}
      </Select>
      <div className={styles.selectSeparator}/>
      <Menu
        selectedKeys={menu}
        defaultOpenKeys={[]}
        mode={"inline"}
        theme={"light"}
      >
        <MenuItem
          key="1"
          icon={
            <Icon
              src={MappingIcon}
              size={Icon_Size.Icon20}
              color={colorConfig}
              selected={menu[0] === "1"}
            />
          }
          onClick={() => onClickMenuItem(`mapping`)}
          className={styles.menuItem}
        >
          Mappings
        </MenuItem>
        <MenuItem
          key="2"
          icon={
            <Icon
              src={RerankResultsIcon}
              size={Icon_Size.Icon20}
              color={colorConfig}
              selected={menu[0] === "2"}
            />
          }
          onClick={() => onClickMenuItem(`rerank`)}
          className={styles.menuItem}
        >
          Re-rank Results
        </MenuItem>
        {/* <MenuItem
          key="3"
          icon={
            <Icon
              src={SynonymsIcon}
              size={Icon_Size.Icon20}
              color={colorConfig}
              selected={menu[0] === "3"}
            />
          }
          onClick={() => onClickMenuItem(`synonyms`)}
          className={styles.menuItem}
        >
          Synonyms
        </MenuItem> */}
        <MenuItem
          key="4"
          icon={
            <Icon
              src={FieldWeightIcon}
              size={Icon_Size.Icon20}
              color={colorConfig}
              selected={menu[0] === "4"}
            />
          }
          onClick={() => onClickMenuItem(`fieldweight`)}
          className={styles.menuItem}
        >
          Field Weight
        </MenuItem>
        <MenuItem
          key="7"
          icon={
            <Icon
              src={SearchPreviewIcon}
              size={Icon_Size.Icon20}
              color={colorConfig}
              selected={menu[0] === "7"}
            />
          }
          onClick={() => onClickMenuItem(`searchpreview`)}
          className={styles.menuItem}
        >
          Search Preview
        </MenuItem>
        <MenuItem
          key="8"
          icon={
            <Icon
              src={InstallIcon}
              size={Icon_Size.Icon20}
              color={colorConfig}
              selected={menu[0] === "8"}
            />
          }
          onClick={() => onClickMenuItem(`install`)}
          className={styles.menuItem}
        >
          Install
        </MenuItem>
        <MenuItem key="5" selectable={false} className={styles.separator}>
          <div />
        </MenuItem>
        <MenuItem
          key="6"
          icon={
            <Icon
              src={AllViewsIcon}
              size={Icon_Size.Icon20}
              color={colorConfig}
              selected={menu[0] === "6"}
            />
          }
          onClick={() => onClickMenuItem(`allviews`)}
          className={styles.menuItem}
        >
          All Views
        </MenuItem>
      </Menu>
    </div>
  );
}
