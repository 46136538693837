import { storeCreator } from "./util";

const store = {
  maxEngines: 1,
  maxCollectionsPerEngine: 1,
  maxViewsPerEngine: 1,
  engines: [],
  currentEngineCollection: [],
  currentEngineView: [],
  disableEngineCreation: true,
  disableCollectionCreation: true,
  disableViewCreation: true,
};

const [useUsageStore, usageStoreApi] = storeCreator((setState, getState) => ({
  ...store,
  setLimit: (data) => {
    setState(() => {
      return {
        maxEngines: data.maxEngines,
        maxCollectionsPerEngine: data.maxCollectionsPerEngine,
        maxViewsPerEngine: data.maxViewsPerEngine,
      };
    });
  },
  getLimit: () => {
    return {
      maxEngines: getState().maxEngines,
      maxCollectionsPerEngine: getState().maxCollectionsPerEngine,
      maxViewsPerEngine: getState().maxViewsPerEngine,
    };
  },
  setEngines: (engines) => {
    let maxEngines = getState().maxEngines;
    setState(() => {
      return {
        engines: engines,
        disableEngineCreation: engines.length >= maxEngines,
      };
    });
  },
  setEngineData: (engine) => {
    let maxCollectionsPerEngine = getState().maxCollectionsPerEngine;
    let maxViewsPerEngine = getState().maxViewsPerEngine;
    setState(() => {
      return {
        currentEngineCollection: engine.collections,
        currentEngineView: engine.views,
        disableCollectionCreation:
          engine.collections.length >= maxCollectionsPerEngine,
        disableViewCreation: engine.views.length >= maxViewsPerEngine,
      };
    });
  },
  reset: () => {
    setState(() => ({
      ...store,
    }));
  },
}));

export { useUsageStore, usageStoreApi };
