import { ElevateService } from "../service";
import { rerankStoreApi } from "../store";

export function getRerankList(currentEngineId, currentViewId, page, size) {
  ElevateService.list(currentEngineId, currentViewId, page, size).then(
    (response) => {
      if (response.elevate) {
        rerankStoreApi
          .getState()
          .setList(response.elevate.docs, response.elevate.numFound);
      }
    }
  );
}
