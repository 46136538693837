import { message } from 'antd';
import 'antd/es/message/style/css';

// message.success(content, [duration], onClose)
// message.error(content, [duration], onClose)
// message.info(content, [duration], onClose)
// message.warning(content, [duration], onClose)
// message.warn(content, [duration], onClose)
// alias of warning
// message.loading(content, [duration], onClose)

export const showSuccess = (msg) => {
  message.success({
    content: msg,
    className: 'custom-toast-message',
    // style: {
    //   marginleft: '',
    // },
    // duration: 60
  });
};

export const showError = (msg) => {
  message.error({
    content: msg,
    className: 'custom-toast-message',
    // style: {
    //   marginTop: '20vh',
    // },
  });
};