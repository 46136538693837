import { MappingService } from "../service";
import { mappingStoreApi } from "../store";

export function getMappings(
  currentEngineId,
  viewId,
  shouldUpdateMapping = false
) {
  MappingService.getMappings(currentEngineId, viewId).then((response) => {
    mappingStoreApi.getState().setMappings(response.mappings);
    if (
      mappingStoreApi.getState().getCurrentMapping().currentMapping === null ||
      shouldUpdateMapping
    ) {
      if (response.mappings.length > 0) {
        // mappingStoreApi
        //   .getState()
        //   .setCurrentMapping(
        //     response.mappings[0],
        //     response.mappings[0].mapping
        //   );
        getMapping(currentEngineId, viewId, response.mappings[0].mapping);
      }
    }
  });
}

export function getMapping(currentEngineId, viewId, mappingId) {
  MappingService.getMapping(currentEngineId, viewId, mappingId).then(
    (response) => {
      mappingStoreApi
        .getState()
        .setCurrentMapping(response.mapping_config, mappingId);
    }
  );
}
