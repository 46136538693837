import { get, post, deleteReq } from "./service";

export const EngineService = {
  getEngines() {
    return get("/engine/GetEngines");
  },
  getEngine(engineName) {
    return get(`/engine/GetEngine?engine=${engineName}`);
  },
  getChild() {
    return get(`/engine/GetChild`);
  },
  createEngine(data) {
    return post("/engine/CreateEngine", { body: data }, true);
  },
  deleteEngine(engineName) {
    return deleteReq(`/engine/DeleteEngine?engine=${engineName}`, {}, true);
  },
};
