import { Modal } from 'antd';
import "antd/es/alert/style/css";

const { confirm } = Modal;

export function showConfirm(data = {}) {
  confirm({
    title: data.title,
    content: data.content,
    icon: "",
    onOk() {
        data.onOk && data.onOk()
    },
    onCancel() {
        data.onCancel && data.onCancel()
    },
    className: 'delete-modal',
    okButtonProps: { className: 'delete-modal-ok' }
  });
}