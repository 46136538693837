import React from "react";
import { Button } from "../../widgets";
import styles from "./styles.module.css";

export function DrawerFooter({ onOkay = () => {}, onCancel = () => {}, disableOk, className }) {
  return (
    <div className={`${styles.footer} ${className}`}>
      <Button key="back" onClick={onCancel}>
        Cancel
      </Button>
      <Button key="submit" type="primary" onClick={onOkay} disabled={disableOk}>
        Save
      </Button>
    </div>
  );
}
