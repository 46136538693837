import React, { useEffect, useState } from "react";
import { Menu, MenuItem, Select, SelectItem, PageHeader } from "../../widgets";
import {
  useEngineStore,
  useCollectionStore,
  collectionStoreApi,
} from "../../store";
import { CollectionService } from "../../service";
import { useHistory } from "react-router-dom";
import { PARAM, getParamFromURL } from "../../utils";
import {
  AllcollectionIcon,
  BookmarkIcon,
  ConfigureIcon,
  DataSourceIcon,
  IndexingIcon,
  TroubleShootIcon,
  SynonymsIcon,
  Icon_Size,
  Icon_Color,
  Icon,
  DropdownIcon,
} from "../../widgets";

import styles from "./styles.module.css";

const colorConfig = {
  default: Icon_Color.Dark,
  selected: Icon_Color.Blue500,
};

export function Collections() {
  let history = useHistory();
  const [menu, setMenu] = useState(["1"]);
  const [configureMenu, setConfigureMenu] = useState(["1"]);
  const [currentPage, setCurrentPage] = useState(null);

  let { collections, currentCollectionId } = useCollectionStore((state) => ({
    collections: state.collections,
    currentCollectionId: state.currentCollectionId,
  }));

  let { currentEngineId, tabChanged } = useEngineStore((state) => ({
    currentEngineId: state.currentEngineId,
    tabChanged: state.tabChanged,
  }));

  const onClickMenuItem = (route, collectionId, configPage) => {
    updateMenu(route);
    if (route !== "allcollections") {
      if (route === "configure" && !!configPage) {
        history.push(
          `/engine/${currentEngineId}/collection/${
            collectionId || currentCollectionId
          }/${route}/${configPage}`
        );
      } else {
        history.push(
          `/engine/${currentEngineId}/collection/${
            collectionId || currentCollectionId
          }/${route}`
        );
      }
    } else {
      history.push(`/engine/${currentEngineId}/collection/${route}`);
    }
  };

  const onCollectionChange = (value) => {
    if (value) {
      CollectionService.getCollection(currentEngineId, value).then(
        (response) => {
          collectionStoreApi
            .getState()
            .setCurrentCollection(response.collection_config, value);
          let route = getParamFromURL(PARAM.collectionPage);
          route = !!route ? route : getParamFromURL(PARAM.collectionId);
          let configPage = getParamFromURL(PARAM.configurePage);
          onClickMenuItem(route, value, configPage);
        }
      );
    }
  };

  useEffect(() => {
    checkPage();
    return function cleanUp() {
      console.log("clean up called");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabChanged]);

  const checkPage = () => {
    let selectedKey = getParamFromURL(PARAM.collectionPage);
    let collectionId = getParamFromURL(PARAM.collectionId);
    let collectionPage = getParamFromURL(PARAM.configurePage);
    if (collectionId === "allcollections") {
      updateMenu(collectionId);
      // setCurrentPage(collectionId);
    } else if (selectedKey === "configure") {
      updateMenu(selectedKey);
      onClickConfigureMenu(collectionPage, collectionId);
    } else {
      updateMenu(selectedKey);
      // setCurrentPage(selectedKey);
    }
  };

  const onClickConfigureMenu = (route, collectionId) => {
    if (route === "indexing") {
      setConfigureMenu(["1"]);
    } else if (route === "collectionsettings") {
      setConfigureMenu(["2"]);
    } else if (route === "allowfieldsettings") {
      setConfigureMenu(["3"]);
    } else if (route === "schedule") {
      setConfigureMenu(["4"]);
    } else if (route === "denyfieldsettings") {
      setConfigureMenu(["5"]);
    }
    history.push(
      `/engine/${currentEngineId}/collection/${
        currentCollectionId || collectionId
      }/configure/${route}`
    );
  };

  const updateMenu = (selectedKey) => {
    if (selectedKey === "datasource") {
      setMenu(["1"]);
      // setCurrentPage("datasource");
    } else if (selectedKey === "schema") {
      setMenu(["2"]);
      // setCurrentPage("schema");
    } else if (selectedKey === "troubleshoot") {
      setMenu(["3"]);
      // setCurrentPage("troubleshoot");
    } else if (selectedKey === "recrawl") {
      setMenu(["4"]);
      // setCurrentPage("indexing");
    }
    if (selectedKey === "allcollections") {
      setMenu(["5"]);
      // setCurrentPage("allcollections");
    }
    if (selectedKey === "configure") {
      setMenu(["6"]);
      // setCurrentPage("configure");
    }
    if (selectedKey === "synonyms") {
      setMenu(["8"]);
      // setCurrentPage("configure");
    }
    setCurrentPage(selectedKey);
  };

  return (
    <div className="sideMenu">
      {currentPage !== "configure" ? (
        <div>
          <Select
            className={`${styles.select} paragraph300`}
            value={currentCollectionId}
            bordered={false}
            onChange={onCollectionChange}
            suffixIcon={<Icon src={DropdownIcon} size={Icon_Size.Icon12} />}
          >
            {collections &&
              collections.map((item) => {
                return (
                  <SelectItem value={item.collection} key={item.collection}>
                    <span className={styles.selectItem}>
                      {/* <Icon
                        src={BookmarkIcon}
                        className={styles.collectionIcon}
                        size={Icon_Size.Icon20}
                      /> */}
                      <span>{item.collection}</span>
                    </span>
                  </SelectItem>
                );
              })}
          </Select>
          <div className={styles.selectSeparator} />
          <Menu
            // style={{ width: 256 }}
            className={`paragraph300 collectionMenu`}
            selectedKeys={menu}
            defaultOpenKeys={[]}
            mode={"inline"}
            theme={"light"}
          >
            <MenuItem
              key="1"
              icon={
                <Icon
                  src={DataSourceIcon}
                  size={Icon_Size.Icon20}
                  color={colorConfig}
                  selected={menu[0] === "1"}
                />
              }
              onClick={() => onClickMenuItem(`datasource`)}
              className={styles.menuItem}
            >
              Data Source
            </MenuItem>
            {/* <MenuItem
              key="2"
              icon={<Icon src={SchemaIcon} />}
              onClick={() => onClickMenuItem(`schema`)}
              className={styles.menuItem}
            >
              Collection schema
            </MenuItem> */}
            {/* <MenuItem
              key="3"
              icon={
                <Icon
                  src={TroubleShootIcon}
                  size={Icon_Size.Icon20}
                  color={colorConfig}
                  selected={menu[0] === "3"}
                />
              }
              onClick={() => onClickMenuItem(`troubleshoot`)}
              className={styles.menuItem}
            >
              Troubleshoot collection
            </MenuItem> */}
            <MenuItem
              key="4"
              icon={
                <Icon
                  src={IndexingIcon}
                  size={Icon_Size.Icon20}
                  color={colorConfig}
                  selected={menu[0] === "4"}
                />
              }
              onClick={() => onClickMenuItem(`recrawl`)}
              className={styles.menuItem}
            >
              Recrawl
            </MenuItem>
            <MenuItem
              key="8"
              icon={
                <Icon
                  src={SynonymsIcon}
                  size={Icon_Size.Icon20}
                  color={colorConfig}
                  selected={menu[0] === "8"}
                />
              }
              onClick={() => onClickMenuItem(`synonyms`)}
              className={styles.menuItem}
            >
              Synonyms
            </MenuItem>
            <MenuItem
              key="6"
              icon={
                <Icon
                  src={ConfigureIcon}
                  size={Icon_Size.Icon20}
                  color={colorConfig}
                  selected={menu[0] === "6"}
                />
              }
              onClick={() => onClickMenuItem(`configure`)}
              className={styles.menuItem}
            >
              Configure Collections
            </MenuItem>
            <MenuItem key="7" selectable={false} className={styles.separator}>
              <div />
            </MenuItem>
            <MenuItem
              key="5"
              icon={
                <Icon
                  src={AllcollectionIcon}
                  size={Icon_Size.Icon20}
                  color={colorConfig}
                  selected={menu[0] === "5"}
                />
              }
              onClick={() => onClickMenuItem(`allcollections`)}
              className={styles.menuItem}
            >
              All Collections
            </MenuItem>
          </Menu>
        </div>
      ) : (
        <div>
          <PageHeader
            className="body400"
            onBack={() => {
              setConfigureMenu(["1"]);
              history.push(`/engine/${currentEngineId}/collection`);
              setTimeout(() => {
                checkPage();
              }, 200);
            }}
            title="Go back"
          />
          <Select
            className={`${styles.select} paragraph300`}
            value={currentCollectionId}
            bordered={false}
            // style={{ width: "100%" }}
            onChange={onCollectionChange}
            suffixIcon={<Icon src={DropdownIcon} size={Icon_Size.Icon12} />}
          >
            {collections &&
              collections.map((item) => {
                return (
                  <SelectItem value={item.collection} key={item.collection}>
                    <span className={styles.menuItem}>
                      {/* <Icon
                        src={BookmarkIcon}
                        className={styles.collectionIcon}
                        size={Icon_Size.Icon20}
                      /> */}
                      <span>{item.collection}</span>
                    </span>
                  </SelectItem>
                );
              })}
          </Select>
          <div className={styles.selectSeparator} />
          <div className={`${styles.configMenu} tiny100`}>Configure Menu</div>
          <Menu
            // style={{ width: 256 }}
            selectedKeys={configureMenu}
            defaultOpenKeys={[]}
            mode={"inline"}
            theme={"light"}
          >
            <MenuItem key="1" onClick={() => onClickConfigureMenu(`indexing`)}>
              <span className={styles.menuItem}>Index configuration</span>
            </MenuItem>
            <MenuItem
              key="2"
              onClick={() => onClickConfigureMenu(`collectionsettings`)}
            >
              <span className={styles.menuItem}>Collection Settings</span>
            </MenuItem>
            <MenuItem
              key="3"
              onClick={() => onClickConfigureMenu(`allowfieldsettings`)}
            >
              <span className={styles.menuItem}>Allow Field Settings</span>
            </MenuItem>
            <MenuItem
              key="5"
              onClick={() => onClickConfigureMenu(`denyfieldsettings`)}
            >
              <span className={styles.menuItem}>Deny Field Settings</span>
            </MenuItem>
            <MenuItem key="4" onClick={() => onClickConfigureMenu(`schedule`)}>
              <span className={styles.menuItem}>Schedule</span>
            </MenuItem>
          </Menu>
        </div>
      )}
    </div>
  );
}
