import { isArray, isString } from "./search";
import styles from "./styles.module.css";

export function formatTableData(data) {
  if (isString(data)) {
    return <span>{data}</span>;
  } else if (isArray(data)) {
    return <span className={styles.tableData}>{data.join(", ")}</span>;
  } else {
    return <span>{data}</span>;
  }
}

export function formatTableDataKSP(data) {
  if (isString(data)) {
    return <span>{data}</span>;
  } else if (isArray(data)) {
    return (
      <span className={styles.tableData}>
        {data.map((item, i) => {
          return <div>{item.split("_ksp")[0]}{i< data?.length - 1 ? "," : ""}</div>;
        })}
      </span>
    );
  } else {
    return <span>{data}</span>;
  }
}
