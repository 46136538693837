import React from 'react';
import { Button as AntButton } from 'antd';
import 'antd/es/button/style/css'; 
import styles from './button.module.css';

export const BUTTON_TYPE = {
    PRIMARY: 'primary',
    SECONDARY: '',
    LINK: 'link',
    TEXT: 'text',
    DASHED: 'dashed'
}

export const BUTTON_SIZE = {
    LARGE: 'large',
    MEDIUM: '',
    SMALL: 'small'
}

export function Button({
    children,
    type=BUTTON_TYPE.SECONDARY,
    size=BUTTON_SIZE.MEDIUM,
    block,
    ...props
}) {
  return (
    <AntButton type={type} size={size} block={block} {...props}>
        {children}
    </AntButton>
  );
}
