import { get, put } from "./service";

export const WeightService = {
  list(engineName, viewName, mapping) {
    return get(
      `/weight/list?engine=${engineName}&view=${viewName}&mapping=${mapping}`
    );
  },
  update(data) {
    return put("/weight/update", { body: data }, true);
  },
};
