import React from "react";
import { Drawer as AntDrawer } from "antd";
import "antd/es/drawer/style/css";
// import styles from './button.module.css';

export function Drawer({
  children,
  contentWrapperStyle = {},
  drawerStyle = {},
  maskStyle = {},
  ...props
}) {
  return (
    <AntDrawer
      maskStyle={{
        ...maskStyle,
        background: "rgba(8, 28, 61,.2)",
      }}
      drawerStyle={{
        ...contentWrapperStyle,
        ...drawerStyle,
        boxShadow: "var(--shadow-slide)",
      }}
      {...props}
    >
      {children}
    </AntDrawer>
  );
}
