import React, { useEffect, useState } from "react";

import {
  Button,
  BUTTON_TYPE,
  Input,
  Form,
  Field,
  FieldList,
  RadioGroup,
  RadioButton,
  Slider,
  Select,
  SelectItem,
} from "../widgets";
import {
  Icon,
  Icon_Size,
  DropdownIcon,
  BluePlusIcon,
  RightArrowIcon,
  Icon_Color,
} from "../widgets";
import { MinusCircleOutlined } from "@ant-design/icons";
import styles from "./styles.module.css";
import { debounce } from "lodash";

const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
const numRegex = /^\d*$/;
export function AddSearchFieldForm({
  onFinish,
  data = {},
  form,
  defaultRecords = [],
  editMode = false,
}) {
  const allqueryFilter = [
    {
      id: "contains",
      label: "Contains",
    },
    {
      id: "gt",
      label: "Greater than",
    },
    {
      id: "lt",
      label: "Less than",
    },
    {
      id: "eq",
      label: "Equal to",
    },
  ];

  const [queries, setQueries] = useState([...allqueryFilter]);

  const onDebounceFieldChange = debounce(() => onFieldsChange(), 300);

  const onFieldsChange = (allFields) => {
    let fields = form.getFieldValue("fields");
    if (fields) {
      updatedQueries(fields);
    }
  };

  useEffect(() => {
    updatedQueries(data.fields || []);
  }, []);

  const updatedQueries = (fields) => {
    let isGt = false;
    let isLt = false;
    let isEq = false;

    fields.forEach((item) => {
      if (item && item.queryType) {
        if (item.queryType === "lt") {
          isLt = true;
        } else if (item.queryType === "gt") {
          isGt = true;
        } else if (item.queryType === "eq") {
          isEq = true;
        }
      }
    });
    if (isLt && isGt && isEq) {
      setQueries([allqueryFilter[0]]);
    } else if (!isLt && !isGt && !isEq) {
      setQueries([...allqueryFilter]);
    } else if (!isLt && !isGt && isEq) {
      setQueries([allqueryFilter[0], allqueryFilter[1], allqueryFilter[2]]);
    } else if (!isLt && isGt && isEq) {
      setQueries([allqueryFilter[0], allqueryFilter[2]]);
    } else if (isLt && !isGt && isEq) {
      setQueries([allqueryFilter[0], allqueryFilter[1]]);
    } else if (isLt && isGt && !isEq) {
      setQueries([allqueryFilter[0], allqueryFilter[3]]);
    } else if (isLt && !isEq) {
      setQueries([allqueryFilter[0], allqueryFilter[1], allqueryFilter[3]]);
    } else if (isLt && isEq) {
      setQueries([allqueryFilter[0], allqueryFilter[1]]);
    } else if (isGt && !isEq) {
      setQueries([allqueryFilter[0], allqueryFilter[2], allqueryFilter[3]]);
    } else if (isGt && !isEq) {
      setQueries([allqueryFilter[0], allqueryFilter[2]]);
    }
  };

  const onResultModifierChange = (data) => {
    form.setFieldsValue(data);
  };

  return (
    <Form
      form={form}
      name="add_search_field_form"
      onFinish={onFinish}
      initialValues={{
        fields: data.fields,
        search_field: data.search_field,
        result_modifier: data.result_modifier,
      }}
      className="addSearchFieldsForm"
      onFieldsChange={(_, allFields) => {
        onDebounceFieldChange();
      }}
    >
      <div className={`${styles.fieldHeader} paragraph300`}>Map field</div>
      <Field
        name="search_field"
        rules={[
          {
            required: true,
            message: "Please input your search field name!",
          },
        ]}
      >
        <Input type="text" readOnly={editMode} />
      </Field>
      {/* <div className={`${styles.fieldHeader} paragraph300`}>Type </div>
      <Field
        name="search_type"
        rules={[
          {
            required: true,
            message: "Please input your view description!",
          },
        ]}
      >
        <Input type="text" />
      </Field> */}
      {/* <div className={`${styles.fieldHeader} paragraph300`}>Search Behavior</div>
      <Field
        name="radio-button"
        rules={[{ required: true, message: "Please pick an item!" }]}
      >
        <RadioGroup defaultValue="a" buttonStyle="solid">
          <RadioButton value="a">Content Only</RadioButton>
          <RadioButton value="b">Display only</RadioButton>
        </RadioGroup>
      </Field> */}
      <div className={`${styles.fieldHeader} paragraph300`}>
        Result modifier
      </div>
      <Field
        name="result_modifier"
        rules={[{ required: true, message: "Please select result modifier" }]}
      >
        <ResultModifier form={form} onFieldChange={onResultModifierChange} />
        <span className={`tiny100`}>
          Display the search results either directly, through the number of
          search counts, or both.
        </span>
      </Field>
      <div className={`${styles.fieldHeader} paragraph300`}>
        Default query filter
      </div>
      <FieldList name="fields">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <div
                key={field.key}
                className={`${styles.queryFieldForm} searchFieldList`}
              >
                <div className={styles.field}>
                  <Field
                    {...field}
                    name={[field.name, "queryType"]}
                    fieldKey={[field.fieldKey, "queryType"]}
                    rules={[{ required: true, message: "Missing field name" }]}
                  >
                    <Select
                      placeholder="Query type"
                      suffixIcon={
                        <Icon src={DropdownIcon} size={Icon_Size.Icon12} />
                      }
                    >
                      {queries.map((item) => {
                        return (
                          <SelectItem value={item.id}>{item.label}</SelectItem>
                        );
                      })}
                    </Select>
                  </Field>
                </div>
                <div className={styles.searchArrow}>
                  <Icon
                    src={RightArrowIcon}
                    size={Icon_Size.Icon16}
                    color={{
                      default: Icon_Color.Dark,
                    }}
                  />
                </div>
                <div className={styles.field}>
                  <Field
                    {...field}
                    name={[field.name, "queryValue"]}
                    fieldKey={[field.fieldKey, "queryValue"]}
                    rules={[
                      { required: true, message: "Missing query value" },
                      {
                        validator: (_, value) =>
                          numberOrDate(_, value, field, form),
                      },
                    ]}
                  >
                    <Input placeholder="Value" />
                  </Field>
                </div>
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </div>
            ))}
            <Field>
              <Button
                type="link"
                onClick={() => add()}
                className={styles.addFieldButton}
                icon={<Icon src={BluePlusIcon} size={Icon_Size.Icon16} />}
              >
                Add filter
              </Button>
            </Field>
          </>
        )}
      </FieldList>
    </Form>
  );
}

function ResultModifier({ value, onFieldChange, form }) {
  const [data, setData] = useState(value);

  useEffect(() => {
    setData(value);
  }, [value]);

  useEffect(() => {
    let fieldvalue = form.getFieldValue("result_modifier");
    setData(fieldvalue);
  }, [form]);

  const handleChange = (e) => {
    onFieldChange({ result_modifier: e });
    setData((e.join && e.join(",")) || e);
  };

  return (
    <Select
      placeholder="Please select result modifier"
      value={data}
      onChange={handleChange}
      suffixIcon={<Icon src={DropdownIcon} size={Icon_Size.Icon12} />}
    >
      <SelectItem value="result">Result</SelectItem>
      <SelectItem value="count">Count</SelectItem>
      <SelectItem value="result,count">Result, Count</SelectItem>
    </Select>
  );
}

function numberOrDate(_, value, field, form) {
  let { fields } = form.getFieldValue();
  let fielddata = fields[field.key];
  if (fielddata.queryType === "lt" || fielddata.queryType === "gt") {
    let parsedValue = parseInt(fielddata.queryValue);
    let validDate = new Date(value);
    let valid = false;

    if((parsedValue && isNumber(value) )|| (isValidDate(value) && validDate.getDate())){
      valid = true;
    }

    if (!valid) {
      return Promise.reject(new Error("values should be number or date in format YYYY-MM-DD"));
    }
  }
  return Promise.resolve();
}

function isValidDate(dateString) {
  return dateRegex.test(dateString)
}

function isNumber(numString){
  return numRegex.test(numString); 
}