import React from "react";
import { Avatar as AntAvatar } from "antd";
import "antd/es/avatar/style/css";
import styles from './avatar.module.css';

export function Avatar({ className, ...props }) {
  return <AntAvatar className={`${styles.avatar} ${className}`} {...props} />;
}

export function AvatarGroup({ ...props }) {
  return <AntAvatar.Group {...props} />;
}
