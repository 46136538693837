import React from "react";
import backgroundWave from "./waves2.svg";
import styles from "./container.module.css";
import { KeySpiderHorizontalIcon, Icon, Icon_Size } from "../../shared/widgets";

export function PageContainer({ children }) {
  return (
    <div className={styles.container}>
      <div className={styles.background}>
        <img src={backgroundWave} alt="background" />
      </div>
      <div className={styles.content}>
        <div className={styles.logo}>
          <Icon
            src={KeySpiderHorizontalIcon}
            alt="logo"
            className={styles.keySpider}
            size={Icon_Size.Icon36}
            width={"auto"}
          />
        </div>
        <div className={styles.card}>{children}</div>
      </div>
    </div>
  );
}
