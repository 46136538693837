import { storeCreator } from "./util";

const store = {
  mappings: [],
  currentMapping: null,
  currentMappingId: null,
};

const [useMappingStore, mappingStoreApi] = storeCreator(
  (setState, getState) => ({
    ...store,
    setMappings: (mappings) => {
      setState(() => {
        return { mappings: mappings };
      });
    },
    getMappings: () => {
      return { mappings: getState().mappings };
    },
    getCurrentMapping: () => {
      return { currentMapping: getState().currentMapping };
    },
    setCurrentMapping: (view, id) => {
      setState(() => {
        return { currentMapping: view, currentMappingId: id };
      });
    },
    reset: () => {
      setState(() => ({
        ...store,
      }));
    },
  })
);

export { useMappingStore, mappingStoreApi };
