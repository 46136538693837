import { storeCreator } from "./util";

const store = {
  collections: [],
  currentCollection: null,
  currentCollectionId: null
};

const [useCollectionStore, collectionStoreApi] = storeCreator((setState, getState) => ({
  ...store,
  setCollections: (collections) => {
    setState(() => {
      return { collections: collections };
    });
  },
  getCollections: () => {
    return { collections: getState().collections };
  },
  getCurrentCollection: () => {
    return { currentCollection: getState().currentCollection };
  },
  setCurrentCollection: (collection, id) => {
    setState(() => {
        return { currentCollection: collection, currentCollectionId: id };
      });
  },
  reset: () => {
    setState(() => ({
      ...store,
    }));
  },
}));

export { useCollectionStore, collectionStoreApi };
