import React from "react";
import { Input as AntInput } from "antd";
import "antd/es/input/style/css";
import styles from "./input.module.css";
const { TextArea, Password } = AntInput;

export function Input({ ...props }) {
  return <AntInput {...props} />;
}

export { TextArea, Password };
