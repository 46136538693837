import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { UpgradeModal } from "../upgrade_modal";
import { Icon, CircleArrowUpIcon, Icon_Size } from "../../widgets";
import { getDateFromEpoch, getChargebeePlan } from "../../utils";
import { useUserStore } from "../../store";
import styles from "./styles.module.css";

var chargeBeeInstance;

const STATUS = {
  in_trial: "Trail",
  active: "Active",
  non_renewing: "Trial"
};

export function PlanInfoCard({
  showOtherInfo = false,
  className,
  children,
  showOnActive = true,
}) {
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [subscription, setSubscription] = useState({});

  let { currentUser, users } = useUserStore((state) => ({
    currentUser: state.currentUser,
    users: state.users,
  }));

  useEffect(() => {
    if (!chargeBeeInstance) {
      initChargeBee();
    } else {
      chargeBeeInstance.registerAgain && chargeBeeInstance.registerAgain();
    }
  }, []);

  useEffect(() => {
    currentUser && setSubscription(currentUser.subscription);
  }, [currentUser]);

  useEffect(() => {
    if (
      subscription &&
      subscription.status === "in_trial" &&
      subscription.trial_end
    ) {
      // let noOfDaysRemaining = daysRemaining(subscription.trial_end);
    }
  }, [subscription]);

  const initChargeBee = () => {
    let _plan = getChargebeePlan()
    chargeBeeInstance = window.Chargebee.init(_plan);
  };

  const openPortal = () => {
    let cbPortal = chargeBeeInstance.createChargebeePortal();
    cbPortal.open({
      close() {
        //close callbacks
        setTimeout(() => {
          window.location.replace(
            window.location.origin + window.location.pathname
          );
        }, 1000);
      },
    });
  };

  const shouldShow = () => {
    return subscription.status === STATUS.in_trial ? true : showOnActive;
  };

  return shouldShow() ? (
    <>
      {children}
      <div className={`paragraph200 ${styles.planCard} ${className}`}>
        <div className={styles.sub}>
          {subscription?.billing_period_unit && (
            <div className={styles.plan}>
              {subscriptionType[subscription.billing_period_unit]} subscription
            </div>
          )}
          <div className={`header500`}>
            {subscription && subscription["plan name"]}
          </div>
        </div>
        {showOtherInfo && (
          <div className={`${styles.otherInfo} paragraph200`}>
            {subscription && (
              <>
                <div>
                  You are currently on the <b>{subscription["plan name"]}</b>{" "}
                  plan.
                </div>
                <div>
                  Your next payment due date will be on
                  <b>
                    {" "}
                    {subscription.current_term_end &&
                      dayjs(
                        getDateFromEpoch(subscription.current_term_end)
                      ).format("MMM D, YYYY")}
                  </b>
                  .
                </div>
              </>
            )}
          </div>
        )}
        <div className={styles.status}>
          <div className={styles.currentStatus}>
            <span>Current Status</span>
            <span className={`body400 ${styles.currentStatusTag}`}>
              ● {subscription && STATUS[subscription.status]}
            </span>
          </div>
          <div
            className={styles.upgradeButton}
            onClick={() => {
              subscription && STATUS[subscription.status] === STATUS.in_trial
                ? setShowUpgrade({})
                : openPortal();
            }}
          >
            <span className={styles.upgradeIcon}>
              <Icon src={CircleArrowUpIcon} size={Icon_Size.Icon16} />
            </span>
            <span className={`${styles.upgradeText} body400`}>
              Upgrade plan
            </span>
          </div>
        </div>
        {showUpgrade && (
          <UpgradeModal
            isOpen={showUpgrade !== null}
            onClose={() => setShowUpgrade(null)}
          />
        )}
      </div>
    </>
  ) : (
    ""
  );
}

const subscriptionType = {
  monthly: "Monthly",
  month: "Monthly",
  year: "Annual",
  yearly: "Annual",
  annual: "Annual",
};
