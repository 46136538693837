import React from "react";
import { Input, Form, Field } from "../widgets";
import styles from "./styles.module.css";

export function AddSearchResultForm({ onFinish, data = {}, form }) {
  return (
    <Form
      form={form}
      name="add_search_result_form"
      onFinish={onFinish}
      initialValues={{
        s_title: data.s_title,
        s_desc: data.s_desc,
        s_link: data.s_link,
      }}
      //   className="addFieldsForm"
    >
      <div className={`${styles.fieldHeader} paragraph300`}>title</div>
      <Field
        name="title"
        rules={[
          {
            required: true,
            message: "Please input title!",
          },
        ]}
      >
        <Input type="text" />
      </Field>
      <div className={`${styles.fieldHeader} paragraph300`}>body</div>
      <Field
        name="body"
        rules={[
          {
            required: true,
            message: "Please input body!",
          },
        ]}
      >
        <Input type="text" />
      </Field>
      <div className={`${styles.fieldHeader} paragraph300`}>url</div>
      <Field
        name="url"
        rules={[
          {
            required: true,
            message: "Please input url!",
          },
        ]}
      >
        <Input type="text" />
      </Field>
    </Form>
  );
}
