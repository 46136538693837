import React, { useEffect, useState } from "react";

import { useUserStore, userStoreApi } from "../../store";

export function AccessControl({
  allowedRoles = [],
  isAnd = false,
  children,
  noAccessPlaceholder = "No Access",
  loadingPlaceholder = "Loading...",
}) {
  const [isallowed, setIsallowed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

//   let { allRoles, currentUser } = useUserStore((state) => ({
//     allRoles: state.allRoles,
//     currentUser: state.currentUser,
//   }));

  useEffect(() => {
    let allowed = isAllowed(allowedRoles);
    setIsLoading(false);
    setIsallowed(allowed);
  }, [allowedRoles]);

  return isLoading ? (
    <div>{loadingPlaceholder}</div>
  ) : (
    <> {isallowed ? children : <div> {noAccessPlaceholder}</div>}</>
  );
}

export function isAllowed(allowedRoles, isAnd) {
  let allowed = false;
  let allRoles = userStoreApi.getState().allRoles;
  let currentUser = userStoreApi.getState().currentUser;
  let currentUserRole = [];
  allRoles.forEach((item) => {
    if (currentUser && currentUser.roles && currentUser.roles[0] === item.role) {
      currentUserRole = item.permissions;
    }
  });
  allowedRoles.forEach((role) => {
    if (currentUserRole.indexOf(role) >= 0) {
      allowed = true;
    }
  });
  return allowed;
}
