import React from 'react';
import { Radio as AntRadio } from 'antd';
import "antd/es/radio/style/css";
// import styles from './button.module.css';

export function Radio({
    children,
    ...props
}) {
  return (
    <AntRadio {...props}>
        {children}
    </AntRadio>
  );
}

export function RadioGroup({
    children,
    ...props
}) {
  return (
    <AntRadio.Group {...props}>
        {children}
    </AntRadio.Group>
  );
}

export function RadioButton({
  children,
  ...props
}) {
return (
  <AntRadio.Button {...props}>
      {children}
  </AntRadio.Button>
);
}