import React from "react";

import { CreateEngine } from "../shared/components";
import { useForm } from "../shared/widgets";

import { useHistory } from "react-router-dom";

import styles from "./setup.module.css";

export function SetupPage() {
  const history = useHistory();
  const [engineForm] = useForm();
  const [collectionForm] = useForm();

  const onCreateFinish = () => {
    history.push("/");
  };
  return (
    <div className={styles.setupPage}>
      <div className={styles.content}>
        <CreateEngine
          onFinish={onCreateFinish}
          engineForm={engineForm}
          collectionForm={collectionForm}
        />
      </div>
    </div>
  );
}
