import { storeCreator } from "./util";
import { usageStoreApi } from './usage.store';

const store = {
  users: [],
  currentUser: null,
  roles: {},
  allRoles: [],
  trialBanner: true,
  trialText: null,
};

const [useUserStore, userStoreApi] = storeCreator((setState, getState) => ({
  ...store,
  setUsers: (users) => {
    setState(() => {
      return { users: users };
    });
  },
  getUsers: () => {
    return { users: getState().users };
  },
  getCurrentUser: () => {
    return { currentUser: getState().currentUser };
  },
  setCurrentUser: (user) => {
    setState(() => {
      console.log("current user", user)
      if(user.allowed_resources) {
        usageStoreApi.getState().setLimit({
          maxEngines: user.allowed_resources.engine,
          maxCollectionsPerEngine: user.allowed_resources.collection,
          maxViewsPerEngine: user.allowed_resources.view,
        });
      }
      return { currentUser: user };
    });
  },
  setRoles: (roles) => {
    let allRoles = [];
    if (roles["custom roles"]) {
      allRoles = [...allRoles, ...roles["custom roles"]];
    }
    if (roles["default roles"]) {
      allRoles = [...allRoles, ...roles["default roles"]];
    }
    setState(() => {
      return { roles: roles, allRoles: allRoles };
    });
  },
  getRoles: () => {
    return { roles: getState().currentUser, allRoles: getState().allRoles };
  },
  setTrialText: (daysRemaining) => {
    setState(() => {
      return {
        trialText: `Your trial plan will expire in ${daysRemaining} day(s).` +
        ` You can continue to use keyspider by upgrading the plan`
      };
    });
  },
  hideTrialBanner: () => {
    setState(() => {
      return { trialBanner: false };
    });
  },
  reset: () => {
    setState(() => ({
      ...store,
    }));
  },
}));

export { useUserStore, userStoreApi };
