import React, { useState } from "react";
import { PageContainer } from "../container";
import { Link } from "react-router-dom";
import { UserService } from "../../shared/service";
import {
  Button,
  BUTTON_TYPE,
  Input,
  Form,
  Field,
  showError,
} from "../../shared/widgets";
import { engineStoreApi } from "../../shared/store";
import styles from "./forgotpassword.module.css";

export function ForgotPasswordPage() {
  const [sucess, setSucess] = useState(false);

  const onFinish = (values) => {
    engineStoreApi.getState().setLoading(true);
    UserService.forgotPassword(values)
      .then((response) => {
        setSucess(true);
      })
      .catch((error) => {
        showError(error.message);
      })
      .finally(() => {
        engineStoreApi.getState().setLoading(false);
      });
  };
  return (
    <PageContainer>
      {!sucess ? (
        <Form
          name="forgot_password_form"
          className={styles.forgotPasswordForm}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Field className={styles.contentRow}>
            <div className={styles.headerContent}>
              <div className="header600">Forgot your password?</div>
              <div className="tiny100">
                {/* Submit your email address and we’ll send you a link to reset
                your password */}
                Enter the email associated with your account and we'll send an
                email with instruction to rest your password.
              </div>
            </div>
          </Field>
          <Field
            name="user_email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
            className={styles.emailRow}
          >
            <Input type="email" placeholder="Email" />
          </Field>
          <Field className={styles.buttonRow}>
            <Button type={BUTTON_TYPE.PRIMARY} htmlType="submit" block={true}>
              Submit
            </Button>
          </Field>
          <Field className="center">
            <Link to="/login">Back to sign in</Link>
          </Field>
        </Form>
      ) : (
        <div className={`${styles.forgotPwdSccuess} center`}>
          <div className="header600">Forgot your password?</div>
          <div className="tiny100">
            A reset mail has been sent to your reistered mail id, kindly follow
            the instructions to reset your password
          </div>
          <Link className="tiny100" to="/login">
            Back to sign in
          </Link>
        </div>
      )}
    </PageContainer>
  );
}
