import { UserService } from "../service";
import { userStoreApi } from "../store";
import { daysRemaining } from "../utils";

export function getUsers() {
  UserService.getUsers().then((response) => {
    if (response.users) {
      userStoreApi.getState().setUsers(response.users);
    }
  });
}

export function getUser() {
  UserService.userDetails().then((response) => {
    if (response.user) {
      userStoreApi.getState().setCurrentUser(response.user);
      calculateTrialDays(response.user);
    }
  });
}

export function getRoles() {
  UserService.availabelRoles().then((response) => {
    if (response) {
      userStoreApi.getState().setRoles(response);
    }
  });
}


function calculateTrialDays(user){
  if(user.subscription && user.subscription.status === "in_trial") {
    let noOfDaysRemaining = daysRemaining(user.subscription.trial_end) || daysRemaining(user.subscription.current_term_end);
    noOfDaysRemaining = noOfDaysRemaining < 0 ? noOfDaysRemaining * -1 : noOfDaysRemaining;
    if(noOfDaysRemaining) {
      userStoreApi.getState().setTrialText(noOfDaysRemaining);
    }
  }
}