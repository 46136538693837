import React from 'react';
import { Tabs as AntTabs } from 'antd';
import "antd/es/tabs/style/css";

// import styles from './button.module.css';

// const { TabPane as } = AntTabs;

export function Tabs({
    ...props
}) {
  return (
    <AntTabs {...props} />
  );
}


export function TabPane({
    ...props
}) {
  return (
    <AntTabs.TabPane {...props} />
  );
}