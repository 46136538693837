import { storeCreator } from "./util";

const store = {
  list: [],
  total: 0,
  editRerank: null,
};

const [useRerankStore, rerankStoreApi] = storeCreator((setState, getState) => ({
  ...store,
  setList: (data, total) => {
    setState(() => {
      return { list: data, total: total };
    });
  },
  getList: () => {
    return { list: getState().list };
  },
  setEdit: (data) => {
    setState(() => {
      return { editRerank: data };
    });
  },
  getEdit: () => {
    return { data: getState().editRerank };
  },
  reset: () => {
    setState(() => ({
      ...store,
    }));
  },
}));

export { useRerankStore, rerankStoreApi };
