import React from "react";
import {
  Button,
  BUTTON_TYPE,
  Input,
  Form,
  Field,
  Select,
  SelectItem,
} from "../widgets";
import { checkName } from "./util";
import styles from "./styles.module.css";

export function ViewForm({
  form,
  onFinish,
  data = {},
  collections = [],
  editMode = false,
}) {
  return (
    <Form
      name="create_view_form"
      form={form}
      onFinish={onFinish}
      initialValues={{
        view: data.view,
        view_description: data.view_description,
        collection_list: data.collection_list,
      }}
      className={styles.createViewForm}
    >
      <div className={`paragraph300 ${styles.fieldHeader}`}>View name</div>
      <Field
        name="view"
        rules={[
          {
            required: true,
            message: "Please input your view name!",
          },
          {
            validator: checkName,
            message: "Only numbers, alphabets, - and _ are allowed",
          },
        ]}
      >
        <Input type="text" readOnly={editMode} />
      </Field>
      <div className={`paragraph300 ${styles.fieldHeader}`}>Description </div>
      <Field name="view_description" rules={[]}>
        <Input type="text" />
      </Field>
      <div className={`paragraph300 ${styles.fieldHeader}`}>Collections </div>
      <Field
        name="collection_list"
        rules={[
          {
            required: true,
            message: "Please select the relevant collections",
            type: "array",
          },
        ]}
      >
        <Select
          mode="multiple"
          placeholder="Please select the relevant collections"
        >
          {collections.map((item) => {
            return (
              <SelectItem value={item.collection}>{item.collection}</SelectItem>
            );
          })}
        </Select>
      </Field>
    </Form>
  );
}
