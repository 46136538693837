import React, { useEffect, useState } from "react";
import { Collections } from "../collections";
import { useEngineStore, engineStoreApi } from "../../store";
import { Views } from "../views";
import { Tabs, TabPane, Icon_Size } from "../../widgets";
import { PARAM, getParamFromURL } from "../../utils";
import {
  Icon,
  CollectionsIcon,
  CollectionsActiveIcon,
  ViewIcon,
  ViewActiveIcon,
  HomeIcon,
  HomeActiveIcon,
} from "../../widgets";
import styles from "./sidemenu.module.css";

export function SideMenu({ onTabChange, showMenu = true, currentTab }) {
  const [defaultTab, setDefaultTab] = useState("3");
  let { currentEngine, tabChanged } = useEngineStore((state) => ({
    currentEngine: state.currentEngine,
    tabChanged: state.tabChanged,
  }));

  useEffect(() => {
    if (!!currentTab) {
      setDefaultTab(currentTab);
    }
  }, [currentTab]);

  useEffect(() => {
    console.log("tab cahnge", tabChanged);
    checkTab();
  }, [tabChanged]);

  const checkTab = () => {
    let tab = getParamFromURL(PARAM.entityType);
    if (tab === "collection") {
      setDefaultTab("2");
    } else if (tab === "dashboard") {
      setDefaultTab("3");
    } else if (tab === "view") {
      setDefaultTab("1");
    } else {
      setDefaultTab("0");
    }
  };

  const onChange = (key) => {
    setDefaultTab(key);
    onTabChange(key);
  };

  const onCollectionClick = () => {
    engineStoreApi.getState().setOnTabChange();
  }
  return (
    <div className={styles.sideMenu}>
      <Tabs
        tabPosition={"left"}
        className="sideNav"
        activeKey={defaultTab}
        onChange={onChange}
      >
        <TabPane
          tab={
            <span className={`${styles.tabIcon} ${styles.tabFirstIcon}`}>
              <div
                className={`${styles.icon} ${
                  defaultTab === "3" ? styles.activeTab : ""
                }`}
              >
                <Icon src={defaultTab === "3" ? HomeActiveIcon : HomeIcon} size={Icon_Size.Icon24}/>
              </div>
              <div>Dashboard</div>
            </span>
          }
          key="3"
        ></TabPane>
        <TabPane
          tab={
            <span className={`${styles.tabIcon}`}>
              <div
                className={`${styles.icon} ${
                  defaultTab === "1" ? styles.activeTab : ""
                }`}
              >
                <Icon src={defaultTab === "1" ? ViewActiveIcon : ViewIcon} size={Icon_Size.Icon24}/>
              </div>
              <div>Views</div>
            </span>
          }
          key="1"
        >
          {showMenu && defaultTab === "1" && (
            <Views data={currentEngine ? currentEngine.views : []} />
          )}
        </TabPane>
        <TabPane
          tab={
            <span className={styles.tabIcon} onClick={onCollectionClick}>
              <div
                className={`${styles.icon} ${
                  defaultTab === "2" ? styles.activeTab : ""
                }`}
              >
                <Icon
                  src={
                    defaultTab === "2" ? CollectionsActiveIcon : CollectionsIcon
                  }
                  size={Icon_Size.Icon24}
                />
              </div>
              <div>Collections</div>
            </span>
          }
          key="2"
        >
          {showMenu && defaultTab === "2" && (
            <Collections
              data={currentEngine ? currentEngine.collections : []}
            />
          )}
        </TabPane>
      </Tabs>
    </div>
  );
}
