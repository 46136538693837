import React from 'react';
import { Breadcrumb as AntBreadcrumb } from 'antd';
import 'antd/es/breadcrumb/style/css'; 
// import styles from './button.module.css';

export function Breadcrumb({
    children,
    ...props
}) {
  return (
    <AntBreadcrumb {...props}>
        {children}
    </AntBreadcrumb>
  );
}

export const BreadcrumbItem = AntBreadcrumb.Item;