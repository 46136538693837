import React, { useEffect, useState } from "react";
import { UpgradeModal } from "../upgrade_modal";
import { useHistory } from "react-router-dom";
import {
  KeySpiderHorizontalIcon,
  TrailExpiredIcon,
  Icon,
  Icon_Size,
  Button,
  BUTTON_TYPE,
  showError,
  BUTTON_SIZE,
} from "../../widgets";
import { useUserStore } from "../../store";
import { UserService } from "../../service";
import { UserPlanService } from "../../service";
import { getUser } from "../../actions";
import { URLS } from "../../constants";

import { getDateFromEpoch, isExpired } from "../../utils";
import styles from "./styles.module.css";

export function AccountStatus({ children }) {
  const [isValid, setIsValid] = useState(true);

  const [showUpgrade, setShowUpgrade] = useState(false);

  const [subscription, setSubscription] = useState({});
  const [status, setStatus] = useState(null);

  let { currentUser } = useUserStore((state) => ({
    currentUser: state.currentUser,
  }));

  useEffect(() => {
    let query = new URLSearchParams(window.location.search);
    let sub_id = query.get("sub_id");
    let cus_id = query.get("cus_id");
    if (sub_id && cus_id) {
      UserPlanService.updateSubscription({
        subscription_id: sub_id,
        customer_id: cus_id,
      })
        .then((response) => {
          if (response) {
            getUser();
          }
        })
        .catch((error) => {
          showError(error.message);
        })
        .finally(() => {
          window.location.replace(
            window.location.origin + window.location.pathname
          );
        });
    }
  }, []);

  useEffect(() => {
    if (subscription.trial_end) {
      let endDate = getDateFromEpoch(subscription.trial_end);
      let accExpired = isExpired(endDate);
      setIsValid(!accExpired);
    }
  }, [subscription]);

  useEffect(() => {
    console.log(currentUser);
    if (currentUser?.subscription) {
      setSubscription(currentUser.subscription);
    }
    if (currentUser?.account_status) {
      setStatus(currentUser.account_status);
      // setStatus("activve");
    }
  }, [currentUser]);

  return (
    <>
      {!isValid ? (
        <InvalidPage
          showUpgrade={showUpgrade}
          onUpgrade={(data) => setShowUpgrade(data)}
        />
      ) : (
        <>{children}</>
      )}
    </>
  );
}

function InvalidPage({ showUpgrade, onUpgrade }) {
  const history = useHistory();
  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <Icon
            src={KeySpiderHorizontalIcon}
            alt="logo"
            className={styles.keySpider}
            size={Icon_Size.Icon36}
          />
        </div>
        <div className={styles.card}>
          <Icon src={TrailExpiredIcon} className={styles.trailExpired} />
          <div className={styles.textContent}>
            <span className={`header600`}>The Free Trial Has Expired</span>
            <span className={`paragraph200`}>
              Crawling is currently paused. To continue the journey of
              exceptional search relevancy, choose from the available plans.
            </span>
          </div>
          <Button type={BUTTON_TYPE.PRIMARY} onClick={() => onUpgrade({})}>
            Upgrade
          </Button>
          <Button
            style={{ margin: "var(--space-8) 0" }}
            type={BUTTON_TYPE.LINK}
            size={BUTTON_SIZE.SMALL}
            onClick={() => {
              localStorage.removeItem("token");
              history.push(`/login`);
            }}
          >
            Back to sign in page
          </Button>
          <Button
            type={BUTTON_TYPE.LINK}
            onClick={() => {
              window.open(URLS.CONTACT_PAGE);
            }}
          >
            Have queries? Contact us.
          </Button>
        </div>
      </div>
      {showUpgrade && (
        <UpgradeModal
          isOpen={showUpgrade !== null}
          onClose={() => onUpgrade(null)}
        />
      )}
    </div>
  );
}

export function NotActivePage({ status }) {
  let history = useHistory();

  const [minikey, setMinikey] = useState(null);

  useEffect(() => {
    let key = localStorage.getItem("mini_key");
    if (key) {
      setMinikey(key);
    } else {
      history.push("/login");
    }
    return function cleanup() {
      localStorage.removeItem("mini_key");
    };
  }, []);

  const onResendEmail = () => {
    let data = {
      mini_key: minikey,
    };
    UserService.resendEmail(data)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        showError(error.message);
      });
  };

  return (
    <div className={styles.page}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <Icon
            src={KeySpiderHorizontalIcon}
            alt="logo"
            className={styles.keySpider}
            size={Icon_Size.Icon36}
          />
        </div>
        <div className={styles.card}>
          <Icon src={TrailExpiredIcon} className={styles.trailExpired} />
          <div className={styles.textContent}>
            <span className={`header600`}>
              Your Keyspider account has not been activated yet
            </span>
            <span className={`paragraph200`}>
              kindly check your mail and activate.
            </span>
          </div>
          <Button onClick={() => onResendEmail()}>
            Resend Confirmation mail
          </Button>
          <Button
            style={{ margin: "var(--space-8) 0" }}
            type={BUTTON_TYPE.LINK}
            size={BUTTON_SIZE.SMALL}
            onClick={() => {
              localStorage.removeItem("token");
              localStorage.removeItem("mini_key");
              history.push(`/login`);
            }}
          >
            Back to sign in page
          </Button>
          <Button
            type={BUTTON_TYPE.LINK}
            onClick={() => {
              window.open(URLS.CONTACT_PAGE);
            }}
          >
            If you have any quesitons? Contact us
          </Button>
        </div>
      </div>
    </div>
  );
}
