import React from 'react';
import { Spin as AntSpin} from 'antd';
import "antd/es/spin/style/css";
// import styles from './button.module.css';

export function Spin({
    ...props
}) {
  return (
    <AntSpin {...props} />
  );
}
