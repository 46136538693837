import { get, post, put, deleteReq } from "./service";

export const MappingService = {
  getMappings(engineName, viewName) {
    return get(`/mapping/GetMappings?engine=${engineName}&view=${viewName}`);
  },
  getMapping(engineName, viewName, mapping) {
    return get(
      `/mapping/GetMapping?engine=${engineName}&view=${viewName}&mapping=${mapping}`
    );
  },
  create(data) {
    return post("/mapping/Create", { body: data }, true);
  },
  update(data) {
    return put("/mapping/Update", { body: data }, true);
  },
  delete(engineName, viewName, mapping) {
    return deleteReq(
      `/mapping/Delete?engine=${engineName}&view=${viewName}&mapping=${mapping}`,
      {},
      true
    );
  },
  listFields(engineName, viewName) {
    return get(`/mapping/ListFields?engine=${engineName}&view=${viewName}`);
  },
  getAPIkey(engineName, viewName, mapping) {
    return get(
      `/mapping/ApiKey?engine=${engineName}&view=${viewName}&mapping=${mapping}`,
      {},
      true
    );
  },
  updateAPIkey(data) {
    return put(`/mapping/UpdateAPIKey`, { body: data }, true);
  },
  listFieldTypes(engineName, collectionName) {
    return get(`/mapping/list_field_types?engine=${engineName}&collection=${collectionName}`);
  },
  createField(engineName, collectionName, field_name, field_type, selector_string, selector_type) {
    return post(`/mapping/CreateField`, { body: {
      engine: engineName,
      collection: collectionName,
      field_name: field_name,
      field_type: field_type,
      selector_string: selector_string,
      selector_type: selector_type,
      stored: true,
      doc_values: false,
      indexed: true,
      multi_valued: true
    }}, true);
  },
  deleteField(engineName, collectionName, field_name) {
    return deleteReq(`/mapping/DeleteField?engine=${engineName}&collection=${collectionName}&field_name=${field_name}`,{}, true);
  },
};
