import React, { useEffect, useState } from "react";
import { useHistory, Route } from "react-router-dom";
import { SetupPage } from "../setup";
import { engineStoreApi } from "../shared/store";
import { getUser } from "../shared/actions";
import { Spin } from "../shared/widgets";
import { EngineService } from "../shared/service";
import { LazyLoad, AccountStatus } from "../shared/components";

import styles from "./styles.module.css";

export function PrivateRoute() {
  let history = useHistory();
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    onStorageChange();
    window.addEventListener("storage", onStorageChange);
    return () => {
      window.removeEventListener("storage", onStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onStorageChange = () => {
    let token = localStorage.getItem("token");
    if (token) {
      setIsAuth(true);
      getUser();
    } else {
      setIsAuth(false);
      history.push("/login");
    }
  };
  return isAuth ? (
    <AccountStatus>
      <Route path="/setup/:engineId" exact>
        <SetupPage />
      </Route>
      <Route path="/setup" exact>
        <SetupPage />
      </Route>
      <Route path="/account">
        <LazyLoad
          component={() => import("../account")}
          componentName={"AccountSettingsPage"}
        />
      </Route>
      <Route path="/engine/:engineId">
        <LazyLoad
          component={() => import("../home")}
          componentName={"HomePage"}
        />
      </Route>
      <Route path="/" exact>
        <RedirectToHomePage />
      </Route>
    </AccountStatus>
  ) : (
    <div className={styles.loading}>
      <span>
        <Spin />
      </span>
    </div>
  );
}

function RedirectToHomePage() {
  let history = useHistory();

  const onLoad = () => {
    EngineService.getEngines()
      .then((response) => {
        if (response.engines && response.engines.length > 0) {
          engineStoreApi.getState().setEngines(response.engines);
          history.push(`/engine/${response.engines[0]}`);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.code === 404) {
          history.push("/setup");
        } else if (error.code === 401) {
          history.push("/login");
        } else if (error.code === 498) {
          localStorage.removeItem("token");
          localStorage.removeItem("mini_key");
        }
      });
  };

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>Loading</div>;
}
