import React from "react";
import {
  Button,
  BUTTON_TYPE,
  Input,
  Form,
  Field,
  FieldList,
  Radio,
  RadioGroup,
  Checkbox,
} from "../widgets";
import { Icon, BluePlusIcon, Icon_Size } from "../widgets";
import { MinusCircleOutlined } from "@ant-design/icons";
import styles from "./styles.module.css";

export function AddSynonymsForm({ onFinish, data = {}, form }) {
  return (
    <Form
      form={form}
      name="add_synonym_form"
      onFinish={onFinish}
      initialValues={{
        fields: data.synonyms,
      }}
      className="addFieldsForm addSynonymsForm"
    >
      <FieldList name="fields">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <div key={field.key} className={styles.addSynonymForm}>
                <Field
                  {...field}
                  name={[field.name, "synonymvalue"]}
                  fieldKey={[field.fieldKey, "synonymvalue"]}
                  rules={[
                    { required: true, message: "sysnonym cannot be empty" },
                  ]}
                >
                  <Input placeholder="Add synonym" />
                </Field>
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </div>
            ))}
            <Field>
              <Button
                type="link"
                onClick={() => add()}
                className={styles.addFieldButton}
                icon={<Icon src={BluePlusIcon} size={Icon_Size.Icon16} />}
              >
                Add new synonyms
              </Button>
            </Field>
          </>
        )}
      </FieldList>
    </Form>
  );
}
